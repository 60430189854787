import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { handleError } from '../../utils';
import lakotaApi, { FINGER_POSITIONS } from '../../ajax/LakotaFingerprints/lakotaApi';
import { LakotaFingerprintsContext } from './LakotaFingerprints';
import { AppContext } from '../../App';
import AnnotateStep from './AnnotateStep';

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress size={80} thickness={2} {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {props.label}
        </Typography>
      </Box>
    </Box>
  );
}

function Placeholder({ width, isCapturing }) {
  return (
    <div
      style={{
        width,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isCapturing ? <CircularProgressWithLabel label="Capturing" /> : <i>Waiting for image</i>}
    </div>
  );
}

let leftFourPollingInterval = null;
let rightFourPollingInterval = null;
let thumbsPollingInterval = null;

export default function CaptureStep() {
  const {
    isReady,
    setErrorMessage,
    setWarningMessage,
    badgeApplication,
    downloadEft,
    attachToBadgeApplication,
    onCancel,
  } = useContext(LakotaFingerprintsContext);
  const app = useContext(AppContext);
  const config = app?.airport?.lakotaFingerprintsConfig;
  const [leftFour, setLeftFour] = useState(null);
  const [leftFourStats, setLeftFourStats] = useState(null);
  const [thumbs, setThumbs] = useState(null);
  const [thumbsStats, setThumbsStats] = useState(null);
  const [rightFour, setRightFour] = useState(null);
  const [rightFourStats, setRightFourStats] = useState(null);
  const [isCapturingInitialPrints, setIsCapturingInitialPrints] = useState(false);
  const [initialPrintsHaveBeenCaptured, setInitialPrintsHaveBeenCaptured] = useState(false);
  const [isCapturingLeftFour, setIsCapturingLeftFour] = useState(false);
  const [isCapturingRightFour, setIsCapturingRightFour] = useState(false);
  const [isCapturingThumbs, setIsCapturingThumbs] = useState(false);
  const [isAnnotateModalOpen, setIsAnnotateModalOpen] = useState(false);

  const scoresContainerStyle = {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 46,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  };

  /**
   * @param {int} quality
   */
  const determineQualityColor = quality => {
    if (quality >= 75) {
      return 'rgba(77, 189, 116, 0.5)';
    } else if (quality >= 50) {
      return 'rgba(255, 193, 7, 0.5)';
    } else {
      return 'rgba(155, 0, 0, 0.5)';
    }
  };

  const getScores = (stats = []) => {
    if (!Array.isArray(stats)) {
      return;
    }
    return (
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        {stats.map(({ quality }, idx) => {
          const borderStyle = '1px solid black';
          let style = {
            borderLeft: borderStyle,
            borderTop: borderStyle,
            borderBottom: borderStyle,
            padding: '5px 8px',
            backgroundColor: determineQualityColor(quality),
          };
          if (idx === 0) {
            style = { ...style, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, borderLeft: borderStyle };
          } else if (idx === stats.length - 1) {
            style = { ...style, borderTopRightRadius: 4, borderBottomRightRadius: 4, borderRight: borderStyle };
          }
          return (
            <Grid item key={idx} style={style}>
              {quality}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const onAnnotateModalClose = () => {
    setIsAnnotateModalOpen(false);
  };

  const processEft =
    ({ isForBadgeApplication = false }) =>
    () => {
      const data = { leftFour, leftFourStats, thumbs, thumbsStats, rightFour, rightFourStats };
      if (isForBadgeApplication) {
        attachToBadgeApplication(data);
      } else {
        downloadEft(data);
      }
    };

  const getPreviewImage = ({ onCaptured }) => {
    lakotaApi
      .getCurrentImage({ config })
      .then(({ complete, data, errorMessage, warningMessage }) => {
        // Other values passed in: status, dpi, format, height, width
        setErrorMessage(errorMessage);
        setWarningMessage(warningMessage);
        if (complete && data) {
          if (leftFourPollingInterval) clearInterval(leftFourPollingInterval);
          if (rightFourPollingInterval) clearInterval(rightFourPollingInterval);
          if (thumbsPollingInterval) clearInterval(thumbsPollingInterval);

          lakotaApi
            .detectFingers({ config })
            .then(stats => {
              lakotaApi.cancelScan({ config }).then(() => {
                onCaptured({ image: data, stats });
              });
            })
            .catch(() => {
              // Do nothing. This happens sometimes.
            });
        }
      })
      .catch(error => {
        lakotaApi.cancelScan({ config });
        handleError({ error });
      });
  };

  const captureLeftFour = () => {
    if (leftFourPollingInterval) clearInterval(leftFourPollingInterval);

    setIsCapturingLeftFour(true);
    setLeftFour(null);
    setLeftFourStats(null);

    return new Promise((resolve, reject) => {
      lakotaApi
        .capture({ config, fingerPositions: FINGER_POSITIONS.LEFT_FOUR })
        .then(() => {
          leftFourPollingInterval = setInterval(() => {
            getPreviewImage({
              onCaptured: ({ image, stats }) => {
                if (leftFourPollingInterval) clearInterval(leftFourPollingInterval);
                setLeftFour(image);
                setLeftFourStats(stats);
                setIsCapturingLeftFour(false);
                console.log(stats);
                resolve();
              },
            });
          }, 250);
        })
        .catch(error => {
          handleError({ error });
          if (leftFourPollingInterval) clearInterval(leftFourPollingInterval);
          lakotaApi.cancelScan({ config });
          reject();
        });
    });
  };

  const captureRightFour = () => {
    if (rightFourPollingInterval) clearInterval(rightFourPollingInterval);

    setIsCapturingRightFour(true);
    setRightFour(null);
    setRightFourStats(null);

    return new Promise((resolve, reject) => {
      lakotaApi
        .capture({ config, fingerPositions: FINGER_POSITIONS.RIGHT_FOUR })
        .then(() => {
          rightFourPollingInterval = setInterval(() => {
            getPreviewImage({
              onCaptured: ({ image, stats }) => {
                if (rightFourPollingInterval) clearInterval(rightFourPollingInterval);
                setRightFour(image);
                setRightFourStats(stats);
                setIsCapturingRightFour(false);
                resolve();
              },
            });
          }, 250);
        })
        .catch(error => {
          handleError({ error });
          if (rightFourPollingInterval) clearInterval(rightFourPollingInterval);
          lakotaApi.cancelScan({ config });
          reject();
        });
    });
  };

  const captureThumbs = () => {
    if (thumbsPollingInterval) clearInterval(thumbsPollingInterval);

    setIsCapturingThumbs(true);
    setThumbs(null);
    setThumbsStats(null);

    return new Promise((resolve, reject) => {
      lakotaApi
        .capture({ config, fingerPositions: FINGER_POSITIONS.THUMBS })
        .then(() => {
          thumbsPollingInterval = setInterval(() => {
            getPreviewImage({
              onCaptured: ({ image, stats }) => {
                if (thumbsPollingInterval) clearInterval(thumbsPollingInterval);
                setThumbs(image);
                setThumbsStats(stats);
                setIsCapturingThumbs(false);
                resolve();
              },
            });
          }, 250);
        })
        .catch(error => {
          handleError({ error });
          if (thumbsPollingInterval) clearInterval(thumbsPollingInterval);
          lakotaApi.cancelScan({ config });
          reject();
        });
    });
  };

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const startFullCapture = () => {
    setIsCapturingInitialPrints(true);
    captureLeftFour()
      .then(() => delay(1000)) // Wait 1 second
      .then(() => captureRightFour())
      .then(() => delay(1000)) // Wait 1 second
      .then(() => captureThumbs())
      .then(() => {
        setIsCapturingInitialPrints(false);
        setInitialPrintsHaveBeenCaptured(true);
      })
      .catch(() => {
        setIsCapturingInitialPrints(false);
        lakotaApi.cancelScan({ config });
      });
  };

  useEffect(() => {
    return () => {
      if (leftFourPollingInterval) clearInterval(leftFourPollingInterval);
      if (rightFourPollingInterval) clearInterval(rightFourPollingInterval);
      if (thumbsPollingInterval) clearInterval(thumbsPollingInterval);
      lakotaApi.cancelScan({ config });
    };
  }, []);

  return (
    <>
      <div style={{ marginTop: 24 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300,
          }}
        >
          <div style={{ height: '100%', border: '1px solid black', position: 'relative' }}>
            <div style={{ position: 'absolute', top: -24, fontWeight: 500, textAlign: 'center', width: '100%' }}>
              LEFT FOUR
            </div>
            {leftFour ? (
              <>
                <img style={{ width: 270 }} src={`data:image/png;base64,${leftFour}`} alt="Left Four Fingerprints" />
                <div style={scoresContainerStyle}>{getScores(leftFourStats)}</div>
              </>
            ) : (
              <Placeholder width={270} isCapturing={isCapturingLeftFour} />
            )}
          </div>
          <div
            style={{
              height: '100%',
              borderTop: '1px solid black',
              borderBottom: '1px solid black',
              position: 'relative',
            }}
          >
            <div style={{ position: 'absolute', top: -24, fontWeight: 500, textAlign: 'center', width: '100%' }}>
              THUMBS
            </div>
            {thumbs ? (
              <>
                <img style={{ width: 240 }} src={`data:image/png;base64,${thumbs}`} alt="Thumbs Fingerprints" />
                <div style={scoresContainerStyle}>{getScores(thumbsStats)}</div>
              </>
            ) : (
              <Placeholder width={240} isCapturing={isCapturingThumbs} />
            )}
          </div>
          <div style={{ height: '100%', border: '1px solid black', position: 'relative' }}>
            <div style={{ position: 'absolute', top: -24, fontWeight: 500, textAlign: 'center', width: '100%' }}>
              RIGHT FOUR
            </div>
            {rightFour ? (
              <>
                <img style={{ width: 270 }} src={`data:image/png;base64,${rightFour}`} alt="Right Four Fingerprints" />
                <div style={scoresContainerStyle}>{getScores(rightFourStats)}</div>
              </>
            ) : (
              <Placeholder width={270} isCapturing={isCapturingRightFour} />
            )}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 16,
          }}
        >
          {!initialPrintsHaveBeenCaptured ? (
            <div style={isCapturingInitialPrints ? { display: 'none' } : null}>
              <Button
                size="lg"
                color="primary"
                onClick={startFullCapture}
                disabled={!isReady || isCapturingInitialPrints}
              >
                <i className="fa-solid fa-fingerprint mr-2"></i>
                {isReady
                  ? isCapturingInitialPrints
                    ? 'Waiting for images...'
                    : 'Start Capture Sequence'
                  : 'Initializing...'}
              </Button>
            </div>
          ) : (
            <>
              <div
                style={{
                  height: '100%',
                  textAlign: 'center',
                  flex: 0.35,
                }}
              >
                <Button color="primary" onClick={captureLeftFour} disabled={!isReady || !thumbs || !rightFour}>
                  Re-capture Left Four
                </Button>
              </div>
              <div
                style={{
                  height: '100%',
                  textAlign: 'center',
                  flex: 0.3,
                }}
              >
                <Button color="primary" onClick={captureThumbs} disabled={!isReady || !leftFour || !rightFour}>
                  Re-capture Thumbs
                </Button>
              </div>
              <div
                style={{
                  height: '100%',
                  textAlign: 'center',
                  flex: 0.35,
                }}
              >
                <Button color="primary" onClick={captureRightFour} disabled={!isReady || !leftFour || !thumbs}>
                  Re-capture Right Four
                </Button>
              </div>
            </>
          )}
        </div>

        <div style={{ marginTop: 100, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button onClick={() => setIsAnnotateModalOpen(true)}>
            <i className="fa-solid fa-asterisk mr-2" />
            Annotate Amputated or Bandaged Fingers
          </Button>

          <div>
            <Button onClick={onCancel} className="mr-4">
              Cancel
            </Button>

            {badgeApplication ? (
              <Button
                color="primary"
                onClick={processEft({ isForBadgeApplication: true })}
                disabled={!leftFour || !thumbs || !rightFour}
              >
                Create EFT and Attach to Badge Application
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={processEft({ isForBadgeApplication: false })}
                disabled={!leftFour || !thumbs || !rightFour}
              >
                <i className="fa-solid fa-cloud-arrow-down mr-2"></i>Download EFT
              </Button>
            )}
          </div>
        </div>

        <AnnotateStep isOpen={isAnnotateModalOpen} onClose={onAnnotateModalClose} />
      </div>
    </>
  );
}
